import React from 'react'
import { pastorErrickData} from '../../Pages/Data';
import { InfoSection } from '../../InfoSection';

export const PastorErrickFordPage = () => {
    return (
        <>
          <InfoSection {...pastorErrickData}/>  
        </>
    )
}
