import React from 'react'
// import { CenteredSec } from '../WelcomeSection/WelcomeSection.styles'
import { CenterImage, Wrapper, CenteredSec } from './DonationSec.styles'
import Tithe from '../../assets/images/tithes2.jpg';

const DonationSec = () => {

    
    return (
        <Wrapper>
            <CenteredSec>
            {/* <video autoPlay loop muted
            style={{
                position: 'absolute',
                width: "100%",
                objectFit: "cover",
                zIndex:"-1",
                height: "600px",

            }} >
                <source src={'https://amplify-fsnchurchapp-dev-170333-deployment.s3.us-east-2.amazonaws.com/videos/eagle_fly.mp4'} type="video/mp4"/>
            </video> */}
            {/* <Tithe/> */}
            <CenterImage src={Tithe}/>
            <a  style={{textDecoration:'none'}} target="_blank" href='https://swipesimple.com/links/lnk_f14e35ce'>
                <h1 >Click here to Give</h1>
            </a>
            <h2 >To Give by $ Cash App</h2>
            <p >$faithspeaksnow</p>
            </CenteredSec>
        </Wrapper>
    )
}

export default DonationSec
