import React from 'react'
import { Container, Button } from '../../GlobalStyle';

import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from './InfoSectionWithButton.styles';

export const InfoSectionWithButton = ({
    img, 
    sectionOneImg, 
    imgStart, 
    lightTextDesc,
    buttonLabel,
    primary,
    linkForButton,
    desc1,
    desc2,
    desc3,
    desc4,
    desc5,
    desc6, 
    headline, 
    lightText, 
    alt, 
    start
}) => {
    return (
    <>
        <InfoSec sectionOneImg={sectionOneImg}>
            <Container>
                <InfoRow imgStart={imgStart}>
                    <InfoColumn>
                        <TextWrapper>
                            <Heading lightText={lightText}>
                            {headline}</Heading>
                            <Subtitle lightTextDesc=
                            {lightTextDesc}>
                                <span>{desc1}</span>
                                <span>{desc2}</span>
                                <span>{desc3}</span>
                                <span>{desc4}</span>
                                <span>{desc5}</span>
                                <span>{desc6}</span>
                            </Subtitle>
                            <a target="youtube" href={linkForButton}>
                            <Button big fontBig primary={primary}>
                                    {buttonLabel}</Button></a>
                        </TextWrapper>
                    </InfoColumn>
                    <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt}/>
                        </ImgWrapper>
                    </InfoColumn>
                </InfoRow>
            </Container>  
        </InfoSec>  
    </>
    )
}

export default InfoSectionWithButton;
