import React, { useState, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { UserContext } from '../Context/UserContext';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Link } from 'react-router-dom';
import ComingSoon from '../Pages/ComingSoon';
import UserProfile from '../UserProfile';
import { Amplify } from 'aws-amplify';

const initialFormState = { username: '', password: '', email: '', authCode: '', formType: 'signIn'}

const Login = () => {
    const [currentUser, setCurrentUser] = useContext(UserContext);
    const [formState, updateFormState] = useState(initialFormState)
    const [authState, setAuthState] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
      checkLoginState()
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            console.log(authData)
        });
    }, []);

    const checkLoginState = async () => {
        try {
          // const currentUser1 = await Auth.currentAuthenticatedUser()
          if (currentUser) {
            setCurrentUser(currentUser)
            // setShowAuthenticator(false)
          }
        } catch (e) {
          setCurrentUser(null)
          setUser(null)
        }
      }

      
    // return authState === AuthState.SignedIn && currentUser ? (
      return (
      <>
        {/* <UserContext.Provider value={[loggedIn, setLoggedIn]}> */}
        {/* <UserProfile /> */}
        {/* </UserContext.Provider> */}
          <AmplifySignOut />
      </>
    // ) : (
    //   <>
    //   <AmplifyAuthenticator />
    //   </>
  );
}

export default withAuthenticator(Login);
