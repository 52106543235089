import { Link } from "react-router-dom";
import styled from "styled-components";

export const SinglePostSelect = styled(Link)`
`;

export const ButtonContainer = styled.div`
    display: flex;
    padding: 15px;
`;

export const CommentsSection = styled.div`
    width: 60vw;
    height: auto;
    padding: 20px 10px;
    background: #C2C2C2;
    margin: 0 auto;
`;

export const TextSection = styled.div`
    width: 70vw;
    height: auto;
    padding: 10px 10px;
    background: #C2C2C2;
    margin: 0 auto;
`;

export const BackToBlogButton = styled(Link)`
`;

export const EditPost = styled(Link)`
`;

export const PostItemWrapper = styled.div`
    margin: 10px 10vw;
    background: var(--orange);
    border-radius: 20px;
    padding: 5px;
    width: auto;
    height: auto;
`;

export const PostTitle = styled.p`
    font-size: 3.5rem;
    text-align: center;
`;

export const PostDateText = styled.p`
    font-size: 1.0rem;
`;

export const PostText = styled.p`
    font-size: 1.3rem;
    color: #000;
`;

export const PostDescripton = styled.p`
    font-size: 1.6rem;
    color: var(--navyBlue);
`;