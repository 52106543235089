import React from 'react'
import { Wrapper, CenteredSec, FamilyFrame, WelcomeLogo, WelcomeImg, ImageWrap } from './WelcomeSection.styles'
// import Heru from ;
import FirstFamilyWelcomePhoto from '../../assets/images/errick_and_pat_no_bkground.png';
import transparentLogo from '../../assets/logos/transparent_white_text_fsn_logo.png';

const WelcomeSection = () => {
    return (
        <Wrapper>
            <WelcomeLogo src={transparentLogo} alt='fsn-trans-logo'/>
                    <ImageWrap>
                    <WelcomeImg src={FirstFamilyWelcomePhoto} alt='fsn-fam'/>
                    </ImageWrap>
                    <p>Mission Statement</p>
            <p>To equip the people to know God and to let God be known in their lives</p>
        </Wrapper>
    )
}

export default WelcomeSection
