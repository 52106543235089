import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Button } from '../../GlobalStyle';

import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    Subtitle,
    ImgWrapper,
    SubtitleV2,
    Img,
    BodyText
} from './InfoSeciton.styles';

export const InfoSection = ({
    img, 
    sectionOneImg, 
    imgStart, 
    lightTextDesc,
    description,
    desc1,
    desc2,
    desc3,
    desc4,
    desc5,
    desc6,
    desc7,
    desc8, 
    headline, 
    lightText, 
    alt, 
    start
}) => {
    return (
    <>
        <InfoSec sectionOneImg={sectionOneImg}>
            <Container>
                <InfoRow imgStart={imgStart}>
                    <InfoColumn>
                        <TextWrapper>
                            <Heading lightText={lightText}>
                            {headline}</Heading>
                            <Subtitle lightTextDesc=
                            {lightTextDesc}>
                                <p>{desc1}</p>
                                <p>{desc2}</p>
                                <p>{desc4}</p>
                                <p>{desc5}</p>
                                {/* <p>{desc6}</p> */}
                                </Subtitle>
                                <BodyText>
                                <p>{desc3}</p>
                                </BodyText>
                                <SubtitleV2 lightTextDesc=
                            {lightTextDesc}>
                                <h2>{desc6}</h2>
                                <Link style={{ textDecoration: 'none' }} to='/pastor-errick-ford'><p>{desc7}</p></Link>
                                <Link style={{ textDecoration: 'none' }} to='/minister-patricia-ford'><p>{desc8}</p></Link>
                            </SubtitleV2>
                        </TextWrapper>
                    </InfoColumn>
                    <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt}/>
                        </ImgWrapper>
                    </InfoColumn>
                </InfoRow>
            </Container>  
        </InfoSec>  
    </>
    )
}

export default InfoSection;
