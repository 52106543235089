import React, { useState, createContext } from "react";


export const UserContext = createContext();

export const defaultUsername = {
    attributes: {
        username: 'Guest',
    }
  };

export const UserProvider = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const [showAuthenticator, setShowAuthenticator] = useState(false);
    const [currentPost, setCurrentPost] = useState();

    return (
        <UserContext.Provider value={[isLoggedIn, setIsLoggedIn, currentPost, setCurrentPost, currentUser, setCurrentUser, showAuthenticator, setShowAuthenticator]}> 
            {/* {props.children} will render all child componets. child conponent will be any component wrapped in MovieContext.Provider tags. */}
            {props.children}
        </UserContext.Provider>
    )
}
