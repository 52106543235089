import React, { useContext, useState } from 'react'
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import Login from '../Login';
import { UserContext } from '../Context/UserContext';
import { wecomePageSec5, wecomePageSec2, wecomePageSec1} from '../Pages/Data';
import { InfoSection } from '../InfoSection';
import WelcomeSection from '../WelcomeSection';
import { InfoSectionWithButton } from '../InfoSectionWithButton';
import DonationSec from '../../components/DonationSec';
import InfoSectionWithCenteredElement from '../InfoSectionWithCenteredElement';

const Home = () => {
    const [ username, setUsername ] = useState('')

    return (
        <>
        {/* <UserContext.Provider value={{username, setUsername}}>
            <Link to='/donate'>Can yu donate? Mr.{username} </Link>
            <Login/>
        </UserContext.Provider> */}
        <WelcomeSection/>
        <InfoSectionWithButton {...wecomePageSec1}/>
        <DonationSec/>
        {/* <InfoSectionWithCenteredElement {...wecomePageSec5}/> */}
        <InfoSection {...wecomePageSec2}/>
        </>
      
    )
}

export default Home