import React from 'react'
import styled from 'styled-components'
import churchImg from '../../assets/images/sunny_praying_hands.jpg'
import { Button } from '../../GlobalStyle'
import ContactForm from '../ContactForm/index'

const Connect = () => {
    return (
        <Wrapper>
            <Section>
                <TitleLine>Connect With Us!</TitleLine>
                <ContactForm/>
                <ConnectionDetailsSec>
                    <LineTitle>Service Times</LineTitle>
                    <Line >Sundays starting at 10am CST</Line>
                    <LineTitle>In Person/Online Bible Study</LineTitle>
                    <Line >Tuesdays starting at 7pm CST</Line>
                    <a target="zoom" href={'https://zoom.us/j/7662869885?pwd=SnFmTVdxSklFSGpvQXZiUEZMVFFNUT09'}>
                    <Button to='https://www.google.com'>Click Here to access Zoom bible study</Button>
                    </a>
                </ConnectionDetailsSec>
            </Section>
        </Wrapper>
    )
}

const LineTitle = styled.h1`
    text-decoration: underline;
    color: var(--navyBlue);
    font-size: 1.5rem;
    text-align: center;
`;

const Line = styled.h1`
    color: var(--lightGrey);
    font-size: 1.3rem;
    text-align: center;
`;

const ConnectionDetailsSec = styled.div`
    padding: 25px;
    margin: 10px;
    border-style: solid;
    border-color: var(--orange);
    color: grey;
    p {
        color: red;
    }
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
`;

const TitleLine = styled.h1`
    text-align: center;
    color: var(--navyBlue);
    font-size: 250%;
`;

const Wrapper = styled.div`
    z-index: 1;
    width: 100%;
    /* max-width: 1300px; */
    margin: 0 auto;
    /* height: 800px; */
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, #282973, #ef4137);
    background-repeat: no-repeat;
    /* background-image:  */
    @media screen and (max-width: 670px) {
        display: block;
        
  }
`;

const Section = styled.div`
    background-color: var(--white);
    /* min-width:600px; */
    height: auto;
    width: 70vw;
    min-width: 70vw;
    border-radius: 14px;
    padding: 40px;
    /* margin: 35px auto; */
    p {
        color: #fff;
    }
`;

export default Connect
