import React from 'react';
import Navbar from '../Navbar';
import { SubMenuItem, SubMenuItemLink } from '../Navbar/Navbar.styles';
// import SubMenuItem from '../Navbar/Navbar.styles';


const Header = () => {
    return (
        <Navbar aboutPageDropDownContent={
            <>
              <SubMenuItemLink to='/about'>
              <SubMenuItem>FSN Church</SubMenuItem>
              </SubMenuItemLink>
              <SubMenuItemLink to='/pastor-errick-ford'>
                  <SubMenuItem>Pastor Errick Ford</SubMenuItem>
                </SubMenuItemLink>
                <SubMenuItemLink to='/minister-patricia-ford'>
                  <SubMenuItem>Minister Patricia Ford</SubMenuItem>
                </SubMenuItemLink>
            </>
          }/>
    )
}

export default Header