export const pastorErrickData = {
    lightBg: false,
    primary: false,
    imgStart:'start',
    lightTopLine: true,
    lightTextDesc: true,
    description: 'Elder, father and husband of Minister Patricia Ford and 3 plus 3 children, author of the book, “Iron Sharpens Iron” and CEO and founder of “SuiteKonnections” social app, and “Kings Collar Designer” clothing. Born to the honorable parents, Willie and Rosie Ford in Pine Bluff, Arkansas with love and respect to them. He now has started a new chapter in his life. \
    Currently he is an Elder and Minister at Christ Cathedral Church under the leadership of Bishop Sherman Allen. He has been ministering the gospel and sharing God’s love for over 25 years of ordination, first at Fountain of Living Waters Church, then Sweethome COGIC and now Christ Cathedral. Now the Lord has called him to the next phase of ministry and leadership spiritually,\
    with the founding of “Faith Speaks Now Church.” He and his wife Patricia accepted God’s call to the next level of ministry with “fresh eyes” as Pastors for the Masters use. Come join the move of God to the next level.',
    desc1: 'Elder, father and husband of Minister Patricia Ford and 3 plus 3 children, author of the book, “Iron Sharpens Iron” and CEO and founder of “SuiteKonnections” social app, and “Kings Collar Designer” clothing. Born to the honorable parents, Willie and Rosie Ford in Pine Bluff, Arkansas with love and respect to them. He now has started a new chapter in his life. ',
    desc2: 'Currently he is an Elder and Minister at Christ Cathedral Church under the leadership of Bishop Sherman Allen. He has been ministering the gospel and sharing God’s love for over 25 years of ordination, first at Fountain of Living Waters Church, then Sweethome COGIC and now Christ Cathedral. Now the Lord has called him to the next phase of ministry and leadership spiritually  with the founding of “Faith Speaks Now Church.” He and his wife Patricia accepted God’s call to the next level of ministry with “fresh eyes” as Pastors for the Masters use. Come join the move of God to the next level.',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    headline: 'Pastor Errick Ford',
    lightText: true,
    topLine: 'Pastor Errick Ford',
    img: require('../../assets/images/errick_bio_shot.jpg').default, 
    alt: 'Image', 
    start: 'true'
}

export const pastorPatricia = {
    lightBg: false,
    primary: true,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    description: '',
    desc1: 'Born to Conwell and Margaret Flood on October 13, 1964 in Hickory North Caroline, moved to Appomattox, Virginia at the age of 2 were she grow up and graduated from Appomattox County High School 1983.',
    desc2: 'Went to Beauty School in 1985, Moved to Arlington, Texas in 1987, Married had four beautiful son Jonathan, Patrick, Justice Williams And her angel Jerrius Williams.',
    desc3: 'Became a member of Christian Outreach Center Church of Fort Worth Texas in 1995, where she rededicated her life to Christ and her true transformation to live for Christ began. Where she heard the word that brought about change to become who God said she was. She received the baptism of the Holy Ghost with in evidence of speaking in tongues. She served in children ministry, hostess, and leader over the women fellowship as well as a prayer leader.',
    desc4: 'She remarried in December 27, 2013 to the king of her life Elder Errick Ford were combined they have 6 children; Erica, Jonathan, Patrick, Destini, Justice’, Errick Jr. And they have 9 grandkids who they love and adore; Kash, Mariyah, Mckale, Jayden, McKenzie, Adea’, Nasir, Zoe, and Love.',
    desc5: 'She accepted the calling of God on her life in 2014. She went to DFW Charis Bible extension school in 2018 and Graduated Charis Bible college 2021 were she received an associates degree in biblical studies. A board member of Perfect Health Holistic Institute and Spiritual advisor and apart of the uplift live radio broadcast for Phhi.',
    desc6: 'Now on December 12, 2021 Errick and Patricia Ford founders of Faith Speak Now Church. All Glory and Praises belong to God for the great work that He has begun in them He is faithful and just to complete it, Amen.',
    headline: 'Minister Patricia Ford',
    lightText: true,
    topLine: 'Minister Patricia Ford',
    img: require('../../assets/images/pat_bio_shot.jpg').default, 
    alt: 'Image', 
    start: 'true'
}

export const wecomePageSec1 = {
    lightBg: false,
    primary: false,
    imgStart:'start',
    lightTopLine: true,
    lightTextDesc: true,
    desc1: 'Join and support our Youtube channel for inspiration and live sermons!',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    headline: 'Church Online',
    buttonLabel: 'Your new Church Home!',
    lightText: true,
    topLine: 'Church Online',
    img: require('../../assets/images/sunny_praying_hands.jpg'), 
    alt: 'Image', 
    start: 'true'
}

export const wecomePageSec2 = {
    lightBg: false,
    primary: false,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    description: '',
    desc7: 'Errick Ford',
    desc8: 'Patricia Ford',
    desc3: 'We believe and therefore we speak and have whatsoever we say, when we believe in our hearts by faith.',
    desc4: '',
    desc5: '',
    desc6: 'Pastors',
    headline: 'WE BELIEVE',
    lightText: true,
    img: require('../../assets/images/errick_and_pat_1.jpg'), 
    alt: 'Image', 
    start: 'true'
}

export const welcomePageSec3 = {
    lightBg: false,
    primary: false,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    doesLogoExist: true,
    description: '',
    desc1: '',
    desc2: '',
    desc3: 'To equip people to know God and to let God be known in their lives. Revealing the true nature of God and awakening them to the authority they have in Christ Jesus. Demonstrating the love of God that is shared abroad in our hearts by the Holy Spirit. To be a living examples of God’s word, by speaking, believing, and receiving the finished work of the cross through grace by faith, that gives us access to the kingdom of God. Teaching the Word of God, that faith without corresponding action is dead. We believe and therefore we speak and have whatsoever we say, when we believe in our hearts by faith.',
    desc4: '',
    desc5: '',
    desc6: '',
    headline: 'Our Mission',
    lightText: true,
    img: require('../../assets/logos/official_fsn_grey.svg').default, 
    alt: 'Image', 
}

export const wecomePageSec4 = {
    lightBg: false,
    primary: false,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    desc1: 'Join and support our Youtube channel for inspiration and powerful live sermons!',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    headline: 'We use the word of God',
    buttonLabel: 'Like & Subscribe',
    lightText: true,
    linkForButton: 'https://www.youtube.com/channel/UCS93gIjfdxBA8G_d4gZdXSA',
    topLine: 'Church Online',
    img: require('../../assets/images/child_reading_bible.jpg'), 
    alt: 'Image', 
    start: 'true'
}

export const wecomePageSec5 = {
    lightBg: false,
    primary: false,
    imgStart:'',
    lightTopLine: true,
    lightTextDesc: true,
    doesButtonExist: true,
    doesLogoExist: false,
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    headline: '',
    buttonLabel: 'Beliefs',
    lightText: true,
    linkForButton: 'https://www.youtube.com/channel/UCS93gIjfdxBA8G_d4gZdXSA',
    topLine: 'Church Online',
    img: require('../../assets/images/child_reading_bible.jpg'), 
    alt: 'Image', 
    start: 'true'
}