import React from 'react'
import { InfoSectionWithCenteredElement } from '../InfoSectionWithCenteredElement';
import { welcomePageSec3 } from '../Pages/Data';
import {  } from '../../components/InfoSectionWithCenteredElement/InfoSectionWithCenteredElement.styles';

const About = () => {
    return (
        <>
        <InfoSectionWithCenteredElement {...welcomePageSec3 }/>            
        </>
    )
}

export default About
