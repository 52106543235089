import React, { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import { NavLinks } from "../Navbar/Navbar.styles";

function LoginButton({ onLogout }) {
    const [currentUser, setCurrentuser] = useContext(UserContext);
    const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);

    if (isLoggedIn) {
        console.log(currentUser.username + " is logged in...");
        return <button className="user-button" onClick={onLogout}>
            {/* <div className="username"> */}
                😀 {currentUser.username}
                : Log Out
            {/* </div> */}
        </button>
    } else {
        console.log("why do I also come?")
        return <>
            <div className="username">
                Welcome Guest 😀
            <button  className="user-button">
            <NavLinks to='/login'>
            <div>
                Log in
            </div>
            </NavLinks>
        </button>
            </div>
        </>
    }
}

export default LoginButton