import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin, FaRegCopyright, FaChurch } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md';
import { GiPrayer } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';
// import {animateScroll as scroll} from 'react-scroll';
// import LHDALogo from '../../images/lionheart-black-circle-with-shade-lrg.svg';
import FSNLogo from '../../assets/logos/fsn-logo-3_circle.svg';

import { 
    FooterContainer, 
    FooterLogo,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinksItems,
    FooterLinkTitle,
    FooterLink,
    SocialLogo,
    WebsiteRights,
    SocialIconLink,
    SocialMedia,
    SocialIcons,
    SocialMediaWrap
} from './Footer.styles'
import { LogoImg, NavLogo } from '../Navbar/Navbar.styles';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const Footer = () => {


    

    return (
        <>
        {/* { loggedin && <AmplifySignOut />} */}
        <FooterContainer>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                <FooterLinksItems>
                <IconContext.Provider value={{ color: '#808285', size: 64 }}>
                    <GiPrayer/>
                    </IconContext.Provider>
                        <FooterLinkTitle>OUR MISSION</FooterLinkTitle>
                        <FooterLink to='/' >To equip people to know God and to let God be known in their lives.</FooterLink>
                    </FooterLinksItems>
                    <FooterLinksItems>
                        <IconContext.Provider value={{ color: '#808285', size: 64 }}>
                        <MdLocationOn />
                        </IconContext.Provider>
                        <FooterLinkTitle>Location</FooterLinkTitle>
                        <FooterLink to='/contact' onClick={() => scroll.scrollToTop()}>7640 NE Loop 820 #110 North Richland Hills, TX</FooterLink>
                        <FooterLinkTitle>Mailing Address</FooterLinkTitle>
                        <FooterLink to='/contact' onClick={() => scroll.scrollToTop()}>PO Box 8365, Fort Worth, TX 76124</FooterLink>
                        <FooterLink to='/contact' onClick={() => scroll.scrollToTop()}>(682) 246-0878</FooterLink>
                    </FooterLinksItems>
                    <FooterLinksItems>
                        <IconContext.Provider value={{ color: '#808285', size: 64 }}>
                            <FaChurch/>
                            </IconContext.Provider>
                        <FooterLinkTitle>FEED YOUR FAITH</FooterLinkTitle>
                        <FooterLink to='/contact' onClick={() => scroll.scrollToTop()}>If your faith don’t speak. Your mountain won’t move</FooterLink>
                    </FooterLinksItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <NavLogo to='/' onClick={() => scroll.scrollToTop()} > 
                        <LogoImg src={FSNLogo} alt='fsn-logo'/>
                    </NavLogo>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' >
                    </SocialLogo>
                        <WebsiteRights>Faith Speaks Now Church <FaRegCopyright/> 2021</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href={'https://www.facebook.com/FaithSpeaksNow/'} target="_blank"
                            arial-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                            <SocialIconLink href={'https://www.youtube.com/channel/UCS93gIjfdxBA8G_d4gZdXSA'} target="_blank"
                            arial-label="YouTube" rel="noopener noreferrer">
                                <FaYoutube/>
                            </SocialIconLink>
                            {/* <SocialIconLink href='/' target="_blank"
                            arial-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink> */}
                            {/* <SocialIconLink href='/' target="_blank"
                            arial-label="Twitter">
                                <FaTwitter/>
                            </SocialIconLink>
                            <SocialIconLink href='/' target="_blank"
                            arial-label="LinkedIn">
                                <FaLinkedin/>
                            </SocialIconLink> */}
                        </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
            
        </FooterContainer>
        </>
    )
}

export default Footer
