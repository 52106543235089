import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Auth, Hub } from 'aws-amplify';
import { UserContext } from "../Context/UserContext";

const UserProfile = () => {
    const [currentUser, setCurrentUser] = useContext(UserContext);
    const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);

    useEffect(() =>{
        authListener()
    }, [])

    async function authListener() {
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                    return setIsLoggedIn(true)
                case "signOut":
                    return setIsLoggedIn(false)
            }
        })
        try {
            const currentUser = await Auth.currentAuthenticatedUser()
            setIsLoggedIn(true)
            setCurrentUser(currentUser)

            // reload the page after post gets deleted
            // window.location.reload();
        } catch(err) {}
    }

    return (
        <>
        <UserDetailsSec>
            <UserInfoItem>Hello, {currentUser.username}</UserInfoItem>
            <UserInfoItem>Email: {currentUser.attributes.email}</UserInfoItem>
            <UserInfoItem>Phone Number Varified: {currentUser.attributes.phone_number_verified}</UserInfoItem>
            <UserInfoItem>Phone Number: {currentUser.attributes.phone_number}</UserInfoItem>
        </UserDetailsSec>    
            {/* <AmplifySignOut /> */}
        </>
    )
}

export default UserProfile

const UserDetailsSec = styled.div`
    margin: 0 auto;
    width: 75vw;
    height: auto;
    background: #c2c2c2c2;
    padding: 5px;
`;

const UserInfoItem = styled.p`
    font-size: 1.2rem;
    color: #000;
`;