import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    background: var(--navyBlue);
    width: 100%;
    height: auto;
    margin: auto 0;
    z-index: -9999;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;

    p {
      font-size: 1.5rem;
      text-align: center;
    }
`;

export const CenteredSec = styled.div`
    margin: auto 0;
    height: auto;
    width: 100%;
    overflow:hidden;
    background: var(--navyBlue);
    justify-content: center;
    align-items:center;
    h1 {
        margin-top: 70px;
        font-size: 3.5rem;
        color: #ffffff;
        text-align: center;
    }
    button {
        text-align: center;
    }
    p {
        margin-bottom: 100px;
        text-align:center;
        padding: 20px;
        font-size: 1.4rem;
        font-style: italic;
    }
    p:hover {
        color: var(--orange)
    }
`;


export const FamilyFrame = styled.div`
    /* margin: 0 auto; */
    padding: 10px;
    
    background: var(--orange);
`;


export const WelcomeLogo = styled.img`
  width: 30rem;
    height: 30rem;
  @media screen and (max-width: 670px) {
    width: 20rem;
    height: 20rem;
  }
`;

export const ImageWrap = styled.div`
  background-color: var(--orange);
  margin-bottom: 40px;
`;

export const WelcomeImg = styled.img`
  width: 30vw;
  padding: 25px;

  @media screen and (max-width: 670px) {
    width: 80vw;
  }
`;