import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useNavigate, useLocation } from "react-router-dom";
import { createPost } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify'
import { AiFillCaretLeft } from 'react-icons/ai';
import { BackToBlogButton } from './PostItem.styles';
import {animateScroll as scroll} from 'react-scroll';
import { v4 as uuid } from "uuid";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
const initialState = { title: '', text: '', media: 'TBD'}

// createdAt: "2022-01-17T05:25:40.432Z"
// id: "b79fe2c6-7840-41b2-8460-3acb00f9f32f"
// media: "test media2"
// text: "test text2"
// title: "test title2"
// updatedAt: "2022-01-17T05:25:40.432Z"

export const AddPostPage = (edit, post) => {
    const [formState, setFormState] = useState(initialState)
    // const [editing, setEditing] = useState(edit)
    // const location = useLocation().state.edit;
    // const location = useLocation().state.stateParam;

    useEffect(async () => {
      // test();
  }, [])

  function test() {
      // console.log('this is the passed prop value: ', location)
  }
    
    function setInput(key, value) {
      setFormState({ ...formState, [key]: value })
    }
    
    const navigate = useNavigate();
    async function addPost() {
        try { 
          console.log('we got this far 1')
          if (!formState.title || !formState.text) return;
          const id = uuid();
          const post = { ...formState }
          post.id = id;
          console.log('we got this far 2')
          console.log(post)

          await API.graphql({
            query: createPost,
            variables: {input: post},
            authMode: "AMAZON_COGNITO_USER_POOLS"});
          console.log('post successfully created', post);
            console.log(id)
          //go to this post page
          navigate('/' + id, {replace: true})

        } catch (err) {
          console.log('error creating post:', err)
        }
      }


  return (
    <>
    <div>
    <BackToBlogButton to='/blog' onClick={() => scroll.scrollToTop()}><AiFillCaretLeft/> back to the blog</BackToBlogButton>
    </div>
    <input
        onChange={event => setInput('title', event.target.value)}
        style={styles.input}
        value={formState.title}
        placeholder="Title"
      />
      {/* <input
        onChange={event => setInput('text', event.target.value)}
        style={styles.input2}
        value={formState.text}
        placeholder="Post Content"
      /> */}
      <SimpleMDE
        value={formState.text}
        onChange={(value) => setFormState({...formState, text: value})}
      />

      {/* {editing ? 
      <button onClick={addPost}>Edit Post</button>
      : */}
      <button onClick={addPost}>Create Post</button>
      {/* } */}

    </>
  )
} 


  const styles = {
    container: { backgroundColor: 'skyblue',width: 400, margin: '0 auto', marginBottom: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    backdrop_continer: { backgroundColor: 'pink',width: 'auto', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    todo: {  marginBottom: 15 },
    input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
    input2: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18, width: '65vw', height: '65vh' },
    todoName: { fontSize: 20, fontWeight: 'bold' },
    todoDescription: { marginBottom: 0 },
    button1: { backgroundColor: 'tomato', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' },
    button2: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' },
    button3: { backgroundColor: 'green', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
  }

export default withAuthenticator(AddPostPage);