import React, { useState, useEffect, useContext } from 'react'
// please read
// https://aws.amazon.com/blogs/aws/new-amazon-cognito-groups-and-fine-grained-role-based-access-control-2/
// import { Auth, Amplify, Hub } from 'aws-amplify';
// import Amplify, { Hub, Auth, AuthModeStrategyType } from 'aws-amplify';
// import awsconfig from './aws-exports'; 
import { Post } from './models'

import Amplify, { AuthModeStrategyType } from 'aws-amplify';
import awsconfig from './aws-exports'; 


import {
  BrowserRouter as Router,
  Routes ,
  Route,
  Link
} from "react-router-dom";
import DonationSec from './components/DonationSec';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Footer from './components/Footer';
import Header from './components/Header';
import { PastorErrickFordPage } from './components/Pages/SubPages/PastorErrickFordPage';
import { PastorPatriciaFordPage } from './components/Pages/SubPages/PastorPatriciaFordPage';
import Sermons from './components/Pages/Sermons';
import Connect from './components/Pages/Connect';
import Login from './components/Login';
import BlogPage from './components/Pages/BlogPage';
import PostPage from './components/Post/PostPage';
import { AddPostPage } from './components/Post/AddPostPage';
import { UserContext, UserProvider } from './components/Context/UserContext';
import { GlobalStyle } from '../src/GlobalStyle';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AmplifySignIn, AmplifyAuthenticator} from "@aws-amplify/ui-react"; 
// Amplify.configure({
//   ...awsconfig,
//   DataStore: {
//     authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
//   }
// })

export const defaultUsername = {
  username: 'Guest'
};

function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  // const [authState, setAuthState] = useState();
  // const [user, setUser] = useState();
  
  
  // const [currentUser, setCurrentUser] = useContext(UserContext);

  // const checkLoginState = async () => {
  //   try {
  //     const currentUser = await Auth.currentAuthenticatedUser()
  //     if (currentUser) {
  //       setCurrentUser(currentUser)
  //       // setShowAuthenticator(false)
  //     }
  //   } catch (e) {
  //     setCurrentUser(defaultUsername)
  //   }
  // }

  // useEffect(async () => {
  //   checkLoginState()
  //   const loadPosts = async () => {
  //     setPosts(await DataStore.query(Post))
  //   }
  //   loadPosts()

  //   const subscription = DataStore.observe(Post).subscribe(() => {
  //     loadPosts()
  //   })

  //   return () => subscription.unsubscribe()
  // }, [])
  // useEffect(() => {
  //   return onAuthUIStateChange((nextAuthState, authData) => {
  //       setAuthState(nextAuthState);
  //       setUser(authData)
  //       console.log(authData)
  //   });
  // }, []);

  return (
      <>
        <Router>
        <GlobalStyle/>
        <UserProvider>
        <Header/>
          <Routes >
            <Route path='/' element={<Home/>}/>
            <Route path='/pastor-errick-ford' element={<PastorErrickFordPage/>}/>
            <Route path='/minister-patricia-ford' element={<PastorPatriciaFordPage/>}/>
            <Route path='/donate' element={<DonationSec/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/sermons' element={<Sermons/>}/>
            <Route path='/connect' element={<Connect/>}/>
            <Route path='/login' element={<Login />}/>
            <Route path='/blog' element={<BlogPage />}/>
            <Route path='/:postId' element={<PostPage />}/>
            <Route path='/create-post' element={<AddPostPage />}/>
          </Routes >
        <Footer/>
        </UserProvider>
        {/* </UserContext.Provider> */}
        </Router>
      </>
  );
}

export default App;


