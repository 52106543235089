import styled from "styled-components";
import { Container } from "../../GlobalStyle";
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
    background: var(--navyBlue);
    /* height: 100px; */
    /* padding: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    position: sticky;
    top: 0;
    z-index: 999;

    @media screen and (max-height: 80px) {
        padding: 10px;
    }
`;

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 72px;
  
    ${Container}
`;

export const NavLogo = styled(Link)`
    /* color: #fff; */
    justify-self: flex-start;
    cursor: pointer;
    /* text-decoration: none;
    font-size: 2rem; */
    display: block;
    align-items: center;
    margin: 0 auto;
`;

export const LogoImg = styled.img`
  width: 250px;
  padding: 25px;

  @media screen and (max-width: 670px) {
    width: 200px;
  }
`;

export const MobileIcon = styled.div`
    /* display: none;
    @media screen and (max-width: 960px) { */
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        background: #fff;
        padding: 5px;
    /* }     */
`;

export const MobileIcon2 = styled.div`
    /* display: none;
    @media screen and (max-width: 960px) { */
        display: block;
        position: absolute;
        top: 0;
        right: 110;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        background: var(--orange);
        padding: 5px;
    /* }     */
`;

export const NavMenu = styled.ul`
    /* display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin: 0 auto; */

    /* @media screen and (max-width: 960px) { */
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 48px;
        left: ${({click}) => (click ? 0: '-100%')};
        opacity: 1;
        transition: all 0.5s ease;
        background: #ef4137;
    /* } */
`;

export const NavItemDropDown = styled.div`
    background-color: #282973;
    font-size: 1.5rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 350px;
    `;

export const SubMenuItem = styled.p`
    text-decoration: none;
    color: #ffffff;
    :hover{
        /* text-decoration: underline; */
        cursor: pointer;
        color: #ef4137;
    }
`;
export const SubMenuItemLink = styled(Link)`
    text-decoration: none;
`;

export const NavItem = styled.div`
    height: 50px;
    border-bottom: 2px solid transparent;
    position: relative;
    :hover ${NavItemDropDown} {
        display: block;
    }

    &:hover {
        border-bottom: 2px solid #ef4137;
    }

    @media screen and (max-width: 960px) {
        width: 100%;

        &:hover {
            border: none;
        }
    }
`;

export const LogInNavItem = styled.div`
    float: left;
    width: auto;
    height: auto;
    /* background: red; */
    padding: 3px;
    /* position: fixed; */
`
export const HorizontalElement = styled.div`
    display: block;
`
export const NavLinks = styled(Link)`
    color: #282973;
    display: flex;
    align-items: center;
    text-decoration: none;
    /* padding: 0.5rem 1rem; */
    height: 100%;

    @media screen and (max-width: 960px) {
        text-align: center;
        /* padding: 2rem; */
        width: 100%;
        display: table;

        &:hover {
            color: #ffffff;
            transition: all 0.3s ease;
        }
    }

`;

export const NavItemBtn = styled.li`
    @media screen and (max-width: 960px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
    }
`;

export const NavBtnLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
`;