import React, { useState, useEffect, useContext } from 'react'
import { deletePost } from '../../graphql/mutations';
import { SinglePostSelect, PostText, ButtonContainer, PostItemWrapper, PostTitle,  } from '../Post/PostItem.styles';
import { getPost } from '../../graphql/queries';
import ComingSoon from './ComingSoon';
import { DataStore, Auth } from 'aws-amplify';
import PostView from '../Post';
import { Post } from '../../models/';
import { UserContext } from '../Context/UserContext';
import { listPosts } from '../../graphql/queries';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const defaultUsername = {
    username: 'Guest'
  };

const BlogPage = () => {
        let showDeleteButton = false
        // if (currentUser) {
        //     showDeleteButton = currentUser.attributes.sub === post.owner
        //     console.log(currentUser.signInUserSession.accessToken.payload)
        //     // console.log(currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admins'))
        //     // || currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admins')
        // }
        const [posts, setPosts] = useState([]);
        const [currentUser, setCurrentUser] = useContext(UserContext);
        const [showAuthenticator, setShowAuthenticator] = useContext(UserContext);
        const [addPost, setAddPost] = useState(false);

        const checkLoginState = async () => {
            try {
            const currentUser = await Auth.currentAuthenticatedUser()
            if (currentUser) {
              console.log("I got here..")
              console.log(currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admins'))
              setCurrentUser(currentUser)

              //checking for user group
              let a = currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admins')
              console.log('this is for the user that is logged in', currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admins'))

              setAddPost(a)
            }
            } catch (e) {
            // setCurrentUser(null)
              console.log("error: ", e)
            }
        }

        async function removePost(post) {
          let id = post.id;
          let version = post._version;
          console.log("removed: ", id);
          console.log("_version: ", version);
          
          try {
            const result = await API.graphql({
              query: deletePost,
              variables: { input: { id, _version: version } },
              authMode: "AMAZON_COGNITO_USER_POOLS"});
              
              console.log("Deleted Post: ", result);
              
              let afterFilter = posts.filter(id => id !== id);
              setPosts(afterFilter)

              // reload the page after post gets deleted
              window.location.reload();
            } catch (err) {
              console.log('error deleting todo:', err)
          }
        }

        
        useEffect(async () => {
          checkLoginState()
        }, [])
        
        useEffect(async () => {
          async function fetchPosts() {
              try {
                  console.log('got this far #0')
                const postData = await API.graphql(graphqlOperation(listPosts))
                console.log('got this far #1')
              //   const post1 = await API.graphql(graphqlOperation(getPost,  {id: "b79fe2c6-7840-41b2-8460-3acb00f9f32f"}));
                console.log('postData:', postData)
                //   console.log('post1:', post1)
                console.log('got this far #2')
                const post = postData.data.listPosts.items
                setPosts(post)
                console.log('got this far #4')
              } catch (err) { console.log('error fetching posts', err) }
            }

          fetchPosts()
      }, [])
        
        return (
        <>
        
        <>
        {addPost && <Link to={{ pathname: '/create-post', state : { editPost: 'false'}}}><button >Add New Post</button></Link>}
        </>
        <div className="post">
            <BlogPostPageTitle>Faith Speaks Now Blog Posts</BlogPostPageTitle>
                {posts.map(post => 
                // <PostView key={post.id} postId={post.id} post={post} currentUser={currentUser}/>
                //data.listPosts.items[0]._deleted
                <div key={post.id}>
                  {!post._deleted &&  
                  <PostItemWrapper >
                  <SinglePostSelect to={`/${post.id}`}>
                    <PostTitle>{post.title}</PostTitle>
                  </SinglePostSelect>
                    <PostText>Published: {post.createdAt}</PostText>
                    <ButtonContainer>
                    {addPost && <div><button onClick={() => removePost(post)}>Delete</button></div>}
                    {addPost && <div><SinglePostSelect to={`/${post.id}`}><button >View Post</button></SinglePostSelect></div>}
                  </ButtonContainer>
                  {/* <button onClick={}>Edit</button> */}
                  </PostItemWrapper>}
                </div>
                )}
            </div>
            </>
    );}

export default BlogPage


const BlogPostPageTitle = styled.h1`
color: #000;
text-align: center;
`;