import React, { useState, useEffect, useContext } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa';
import { VscMail, VscOutput } from 'react-icons/vsc';
import PDF from '../../assets/docs/bylaws_2022.pdf';
import {animateScroll as scroll} from 'react-scroll';
import { IconContext } from 'react-icons/lib';
import { Button } from '../../GlobalStyle';
import { DataStore, Auth, Hub } from 'aws-amplify';
import LoginButton from '../Login/LoginButton'
import LoginPopup from '../Login/LoginPopup';
import { AuthState } from '@aws-amplify/ui-components';
import { Post } from '../../models'
// import LHDALogo from '../../images/lionheart-black-circle-with-shade-lrg.svg';

import { 
    Nav, 
    NavbarContainer, 
    NavLogo,
    HorizontalElement,
    LogInNavItem, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavItemBtn,
    NavBtnLink,
    NavItemDropDown
} from './Navbar.styles';
import { UserContext } from '../Context/UserContext';

// Home
// About
// Ministries
// sermons
// connect
// login
// give
// blog
// social media handles


const Navbar = ({aboutPageDropDownContent}) => {
    const [currentUser, setCurrentUser] = useContext(UserContext);
    const [isLoggedIn, setIsLoggedIn] = useContext(UserContext);
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [dropDown, setDropDown] = useState(false);
    
    const handleClick = () => setClick(!click);
    const dropDownToggler = () => setDropDown(!dropDown);

    useEffect(() => {
        checkLoginState()
    }, [])
    // const [showAuthenticator, setShowAuthenticator] = useState(false)
    async function authListener() {
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                    return setIsLoggedIn(true)
                case "signOut":
                    return setIsLoggedIn(false)
            }
        })
        try {
            const currentUser = await Auth.currentAuthenticatedUser()
            setIsLoggedIn(true)
            setCurrentUser(currentUser)

            // reload the page after post gets deleted
            // window.location.reload();
        } catch(err) {}
    }
    const checkLoginState = async () => {
        try {
          const currentUser1 = await Auth.currentAuthenticatedUser()
          if (currentUser1) {
            setCurrentUser(currentUser1)
            setIsLoggedIn(true)
            // setShowAuthenticator(false)
          }
        } catch (e) {
          setCurrentUser(null)
        }
      }

    return (
        <>
        <IconContext.Provider value={{ color: '#000', style: { verticalAlign: 'middle' } }}>
            <Nav id="navbar">
                <NavbarContainer>
                    <HorizontalElement>
                    <MobileIcon>
                    <LogInNavItem>
                    <LoginButton
                        // onLogin={() => setShowAuthenticator(true)}
                        currentUser={currentUser}
                        onLogout={async () => {
                            await Auth.signOut()
                            checkLoginState()
                            // authListener()
                            setIsLoggedIn(false)
                            window.location.reload();
                        }} /></LogInNavItem>
                        
                            </MobileIcon>
                    <MobileIcon onClick={handleClick}>
                        {click ? <FaTimes/>: <FaBars/>}
                    </MobileIcon>
                    </HorizontalElement>
                    <NavMenu onClick={handleClick} click={click}>
                        <NavItem>
                            <NavLinks to='/' onClick={() => scroll.scrollToTop()}>HOME</NavLinks>
                        </NavItem>
                        <NavItem >
                            <NavLinks to='/about' onClick={() => scroll.scrollToTop()}>ABOUT</NavLinks>
                                <NavItemDropDown >{aboutPageDropDownContent}</NavItemDropDown>
                        </NavItem>
                        
                        <NavItem>
                            <NavLinks to='/sermons' onClick={() => scroll.scrollToTop()}>SERMONS</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/connect' onClick={() => scroll.scrollToTop()}>CONNECT</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/donate' onClick={() => scroll.scrollToTop()}>GIVE</NavLinks>
                        </NavItem>
                        <NavItem>
                        {/* {button ? ( */}
                                    <NavLinks to='/login' onClick={() => scroll.scrollToTop()}>LOGIN</NavLinks>
                                {/* ) : (
                                    <NavLinks to='/login' onClick={() => scroll.scrollToTop()}>LOGIN</NavLinks>
                                )} */}
                        </NavItem>
                        <NavItem>
                            <NavLinks to='/blog' onClick={() => scroll.scrollToTop()}>BLOG</NavLinks>
                        </NavItem>
                        <NavItem>
                            <a href={PDF} target='_blank' rel='noopener noreferrer'>
                                <Button blue>
                                    Beliefs <VscOutput size={28} color='#ef4137'/>
                                    </Button>
                            </a>
                        </NavItem>                    
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
