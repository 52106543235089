import styled, {css} from "styled-components";

export const sharedStyles = css`
  background-color: #eee;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-family: "Raleway", sans-serif;
  margin: 0.5em 0;
  position: relative;
  p {
    color: red;
  }
`;

export const StyledForm = styled.form`
  /* flex-basis: 50%; */
  padding: 40px;
  height: 50rem;
  background-color: var(--navyBlue);
  opacity: .8;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* min-width: 85vw; */
  min-height: 900px;
`;

export const StyledInput = styled.input`
  display: block;
  border: 1px transparent;
  width: 100%;
  ${sharedStyles}
`;

export const StyledTextArea = styled.textarea`
  background-color: #eee;
  width: 100%;
  min-height: 300px;
  resize: none;
  ${sharedStyles}
`;

export const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  /* color: ${props => props.color || '#4d4d4d'} */
  /* color:red */
`;

export const StyledButton = styled.button`
  background-color: var(--lightGrey);
  width: 100%;
  height: 50px;
  border-radius: 15px;
  font-size: 1.4rem;
  color: var(--white)
`;