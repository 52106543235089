import React from 'react'
import * as yup from 'yup';
import { Formik } from 'formik';
import emailjs from 'emailjs-com';
import { StyledForm, StyledInput, Label, Text, StyledTextArea, StyledButton } from './ContactForm.styles';

const validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(2)
      .required(),
    phonenumber: yup
        .string()
        .required()
        .matches(RegExp(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          ), 'Phone number is not valid')
        .min(10, "to short")
        .max(10, "to long"),
    email: yup
      .string()
      .email()
      .required(),
    message: yup
     .string()
     .max(200)
     .required()
 })

const ContactForm = () => {

    const sendEmail = (formData) => {
        console.log("Form Submitted", formData);
        emailjs.send(
          "service_rt34jhm",
          "template_6y5ns6c",
          formData,
           "user_ClQduWSiUb8DSye0iMuxq")
           .then(res => {
             console.log(res);
             console.log('We made it!')
           }).catch(err => console.log(err));
        }
    return (
        <>
        <Formik
          initialValues={{ name: "", phonenumber:"", email: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={values => {
            if(validationSchema.isValid) {
              sendEmail(values);
              alert(JSON.stringify('Message Sent'));
            }
          }}
          render={({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
                <StyledForm onSubmit={handleSubmit}>
                <Label>
                {errors.name && <Text color="red">{errors.name}</Text>}
                <StyledInput
                    type="text"
                    name="name"
                    placeholder="Name*..."
                    value={values.name}
                    border={errors.name && "1px solid red"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                </Label>
                <Label>
                {errors.phonenumber && <Text color="red">{errors.phonenumber}</Text>}
                <StyledInput
                    type="text"
                    name="phonenumber"
                    placeholder="Phone Number..."
                    value={values.phonenumber}
                    border={errors.phonenumber && "1px solid red"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                </Label>
                <Label>
                {errors.email &&<Text color="red">{errors.email}</Text>}
                <StyledInput
                    type="text"
                    name="email"
                    placeholder="Email*..."
                    value={values.email}
                    border={errors.email && "1px solid red"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                </Label>
                <Label>
                {errors.message && <Text color="red">{errors.message}</Text>}
                <StyledTextArea
                    type="text"
                    name="message"
                    placeholder="Message*..."
                    value={values.message}
                    border={errors.message && "1px solid red"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                </Label>
                <StyledButton type="submit">Send Message</StyledButton>
                </StyledForm>
            )}
            />
            </>
    )
}

export default ContactForm