import React from 'react'
import InfoSectionWithButton from '../InfoSectionWithButton';
import { wecomePageSec4} from '../Pages/Data';

const Sermons = () => {
    return (
        <>
          <InfoSectionWithButton {...wecomePageSec4}/>  
        </>
    )
}

export default Sermons
