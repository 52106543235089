import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {animateScroll as scroll} from 'react-scroll';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useParams } from 'react-router';
import { getPost } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { TextSection, BackToBlogButton, EditPost, PostText, PostDateText, PostItemWrapper, PostTitle,  } from './PostItem.styles';
// import {animateScroll as scroll} from 'react-scroll';
import { deletePost } from '../../graphql/mutations';

const dataFormat = {
    data: {
        getPost: {
            id: '',
            text: '',
            title: ''
        }
    }
}
const PostPage = () => {
    const { postId } = useParams();
    const [comments, setComments] = useState([]);
    // const post = usePostFetch(postId);
    const [post, setPost] = useState(dataFormat);
    const [ version, setVersion ] = useState();
    const navigate = useNavigate();

    useEffect(async () => {
        console.log(postId);
        try{

            fetchSinglePost()
            // setPost(postForPage.data.getPost)
        //   console.log('After Getting Post Data from Api:', postForPage)
        } catch(err) {
            console.log('*****************************')
            console.log("Error Description: ", err);
            console.log('*****************************')
        }
    }, [])
    
    async function fetchSinglePost() {
        try {
            console.log('got this far #0')
          const postData = await API.graphql(graphqlOperation(getPost,  {id: postId}))
          console.log(postData)
          setPost(postData)
          console.log(version)
          setVersion(postData.data.getPost._version)
          console.log("version: ", version)
        //   setVersion()
          setComments(postData.data.getPost.comments.items)
          console.log(comments)
        } catch (err) { console.log('error fetching posts',err) }
      }

      async function removePost() {
        // console.log("removed: ",id);
        try {
            let id = postId;
            console.log(post)
          const result = await API.graphql({
              query: deletePost,
              variables: { input: { id, _version: version } },
              authMode: "AMAZON_COGNITO_USER_POOLS"});
              
          console.log("Deleted Post: ", result);
          //go to this post page
        //   navigate('/blog', {replace: true})
        //   const deletedTodoId = result.data.deleteTodo.id;
        //   console.log("Deleted Post ID: ", deletedTodoId)
        //   setTodos(todos.filter(todo => todo.id !== id));
        } catch (err) {
          console.log('error deleting todo:', err)
        }
      }

      async function editPost() {
        // console.log("removed: ",id);
        try {
            let id = postId;
            console.log(post)
          navigate('/create-post', {replace: true})
        //   const deletedTodoId = result.data.deleteTodo.id;
        //   console.log("Deleted Post ID: ", deletedTodoId)
        //   setTodos(todos.filter(todo => todo.id !== id));
        } catch (err) {
          console.log('error deleting todo:', err)
        }
      }

    return(
                <PostItemWrapper>
                    <BackToBlogButton to='/blog' onClick={() => scroll.scrollToTop()}><AiFillCaretLeft/> back to the blog</BackToBlogButton>
                    {/* <button onClick={removePost}>Delete</button> */}
                    {/* <EditPost to={{
                        pathname: '/create-post',
                        state: {stateParam: true }
                    }}><button >Edit</button></EditPost> */}
                    {post.data.getPost.createdAt ? <PostTitle>{post.data.getPost.title}</PostTitle>: <h1>data coming... </h1>}
            {post.data.getPost.createdAt && <PostDateText>published on: {post.data.getPost.createdAt}</PostDateText>}
            <TextSection>{post.data.getPost.text && <PostText>{post.data.getPost.text}</PostText>}</TextSection> 
            {/* {comments && <CommentsSection> */}
            {/* {comments.map(comment => <div>{comment}</div>)} */}
            {/* </CommentsSection>} */}
                </PostItemWrapper>
        );
    }   

export default PostPage;
