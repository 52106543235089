import React from 'react'
import { Container, Button } from '../../GlobalStyle';
import FSNLogo2 from '../../assets/logos/fsn-logo-3_circle.svg';
import PDF from '../../assets/docs/Finalized_Bylaws.pdf';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    Subtitle,
    LogoImg,
    Img
} from './InfoSectionWithCenteredElement.styles';

export const InfoSectionWithCenteredElement = ({
    img,
    doesLogoExist, 
    sectionOneImg, 
    imgStart, 
    lightTextDesc,
    buttonLabel,
    doesButtonExist,
    primary,
    desc1,
    desc2,
    desc3,
    desc4,
    desc5,
    desc6, 
    headline, 
    lightText, 
    alt, 
    start
}) => {
    return (
    <>
        <InfoSec sectionOneImg={sectionOneImg}>
            <Container>
                <InfoRow imgStart={imgStart}>
                    <InfoColumn>
                        <TextWrapper>
                            {doesLogoExist && <LogoImg src={FSNLogo2} alt='fsn-logo-2'/>}
                            <Heading lightText={lightText}>
                            {headline}</Heading>
                            {/* <ImgWrapper> */}
                            {/* </ImgWrapper> */}
                            <Subtitle lightTextDesc=
                            {lightTextDesc}>
                                <p>{desc1}</p>
                                <p>{desc2}</p>
                                <p>{desc3}</p>
                                <p>{desc4}</p>
                                <p>{desc5}</p>
                                <p>{desc6}</p>
                            </Subtitle>
                            <a href={PDF} target='_blank' rel='noopener noreferrer'>
                            {/* <a target="_blank" href="https://www.youtube.com/"> */}
                            {doesButtonExist && (<Button big fontBig primary={primary}>
                                    {buttonLabel}</Button>)}</a>
                        </TextWrapper>
                    </InfoColumn>
                    {/* <InfoColumn>
                        <ImgWrapper start={start}>
                            <Img src={img} alt={alt}/>
                        </ImgWrapper>
                    </InfoColumn> */}
                </InfoRow>
            </Container>  
        </InfoSec>  
    </>
    )
}

export default InfoSectionWithCenteredElement;