import React from 'react'
import { pastorPatricia} from '../../Pages/Data';
import { InfoSection } from '../../InfoSection';

export const PastorPatriciaFordPage = () => {
    return (
        <>
          <InfoSection {...pastorPatricia}/>  
        </>
    )
}