import styled from "styled-components";
import Tithe from '../../assets/images/tithes2.jpg';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: auto;
    /* background: gray; */
    /* padding: 0 20px; */
    margin: 0 auto;
    /* overflow:hidden; */
    background-repeat: no-repeat;
    background: var(--lightGrey);
    `;

export const CenterImage = styled.img`
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: block;
    width: 500px;
    @media screen and (max-width: 768px) {
        width: 300px;
    }
`;

export const CenteredSec = styled.div`
    justify-content: center;
    align-items: center;
    margin: auto 0;
    height: auto;
    width: 100%;
    overflow:hidden;
    background-repeat: no-repeat;
    padding-top: 40px;
    /* background: var(--lightGrey); */
    /* background: require('../../assets/images/tithes3.jpg'); */
    h1 {
        margin-top: 70px;
        font-size: 3.0rem;
        color: #ffffff;
        text-align: center;
    }
    h2 {
        text-align: center;
        color: #ffffff;
        font-size: 3.0rem;
    }
    button {
        text-align: center;
    }
    p {
        margin-bottom: 100px;
        text-align:center;
        padding: 20px;
        font-size: 2.4rem;
        font-style: bold;
    }
    p:hover {
        color: var(--orange)
    }
    h1:hover {
        color: var(--orange)
    }
`;